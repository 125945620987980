<script context="module">
  import { twMerge } from 'tailwind-merge';
  let count = 0;
  export const getId = () => count++;
</script>

<script lang="ts">
  let clazz: string = '';
  export let value: string | number = '';
  export let localValidationError: boolean = false;
  export let showErrorWhenPristine: boolean = false;
  export let showErrorWhenFocused: boolean = false;
  export let customValidityMessage: string = '';
  export let onlyIncludeErrorSpaceOnError: boolean = false;
  export let id: string = `text-field-${getId()}`;
  export let label: string = '';
  export { clazz as class };
  import { omit } from 'shared/src/utils/omit';
  import { onDestroy, onMount } from 'svelte';

  let numericValue: number;
  let stringValue: string;

  type T = $$Generic<HTMLInputElement['type']>;
  export let type: HTMLInputElement['type'] = 'text';

  interface $$Props extends Partial<HTMLInputElement> {
    /**
     * A label for the input element. Displayed as a notched floating label on
     * the input border, a la material design.
     */
    label?: string;
    type?: T;
    /** Bound value of the input */
    value: T extends 'number' ? number : string;
    /** Classes to apply to the input element */
    class?: string;
    /** Element id, or autogenerated for label purposes */
    id?: string;
    /**
     * A boolean for local (parent-component) validation checks. If either this,
     * or the inputs build in validation are invalid, the input is in an errored
     * state.
     */
    localValidationError?: boolean;
    /**
     * A custom validation message to replace the message provided by the
     * input element's validation. Visible if localValidationError is true
     * or if the built in validation occurs.
     */
    customValidityMessage?: string;
    /**
     * If true, we'll always show an error message if one exists, even if
     * the element is in a pristine state.
     */
    showErrorWhenPristine?: boolean;
    /**
     * If true, we'll always show an error message if one exists, even if
     * the element is focused.
     */
    showErrorWhenFocused?: boolean;
    /**
     * If true, only render the error div if there is an error. The div takes up
     * space that might be otherwise unnecessary.
     */
    onlyIncludeErrorSpaceOnError?: boolean;
  }

  /**
   * The html input element
   */
  let input: HTMLInputElement;

  /**
   * Is the input untouched? (i.e., starting value unaltered?)
   */
  let pristine: boolean = true;

  /**
   * The default validation message given by the component
   */
  let validityErrorMessage = '';

  /**
   * Does the current element have focus?
   */
  let isFocused = false;

  const onIncomingChange = (val: string | number) => {
    if (type === 'number') {
      numericValue = Number(value);
    } else {
      stringValue = `${value}`;
    }
  };

  const onOutgoingChange = (val: string | number) => {
    value = type === 'number' ? numericValue : stringValue;
  };

  $: onIncomingChange(value);
  $: onOutgoingChange(type === 'number' ? numericValue : stringValue);

  // Update the error message from the input if needed:
  $: if (input && (value !== '' || !pristine)) {
    validityErrorMessage = input.validationMessage;
    pristine = pristine;
  }

  // If the value has been changed, it's no longer pristine:
  const removePristine = () => {
    pristine = false;
  };

  // Track focus state
  const focusEnter = () => {
    isFocused = true;
  };
  const focusExit = () => {
    isFocused = false;
  };

  // Mark the input invalid if localValidationError is true
  $: {
    input?.setCustomValidity(
      localValidationError ? customValidityMessage || input?.validationMessage || 'Error' : ''
    );
  }

  onMount(() => {
    validityErrorMessage = input.validationMessage;
    input.addEventListener('input', removePristine);
    input.addEventListener('focus', focusEnter);
    input.addEventListener('blur', focusExit);
  });
  onDestroy(() => {
    if (!input) return;
    input.removeEventListener('input', removePristine);
    input.removeEventListener('focus', focusEnter);
    input.removeEventListener('blur', focusExit);
  });

  $: errorIsVisible =
    // If either the input does NOT validate, or we DO get a local validation
    // error from a parent
    (!input?.validity.valid || localValidationError) &&
    // And it's not pristine or we dont care
    (!pristine || showErrorWhenPristine) &&
    // And it's not focused or we don't care
    (!isFocused || showErrorWhenFocused);

  // $: if (input?.type === 'password') {
  //   console.log({
  //     value,
  //     errorIsVisible,
  //     inputIsValid: !input?.validity.valid,
  //     localValidationError,
  //     pristine,
  //     showErrorWhenPristine,
  //     isFocused,
  //     showErrorWhenFocused
  //   });
  // }

  $: props = omit($$props, 'value');
</script>

<div class={twMerge(`relative ${clazz}`)} style="--label-transition-speed: 0.1s;">
  {#if type === 'number'}
    <input
      type="number"
      bind:this={input}
      {...omit(props, 'type')}
      on:input
      on:change
      on:focus
      on:blur
      on:keypress
      on:keydown
      on:keyup
      {id}
      class:error={errorIsVisible}
      class="
      w-full
    border-2
    rounded-md
    px-5
    py-3
    "
      bind:value={numericValue}
    />
  {:else}
    <input
      bind:this={input}
      {...props}
      on:input
      on:change
      on:focus
      on:blur
      on:keypress
      on:keydown
      on:keyup
      {id}
      class:error={errorIsVisible}
      class="
      w-full
    border-2
    rounded-md
    px-5
    py-3
    "
      bind:value={stringValue}
    />
  {/if}

  {#if label}
    <label for={id} class:not-empty={value !== ''}>
      <div>{label}</div>
    </label>
  {/if}
  {#if !onlyIncludeErrorSpaceOnError || errorIsVisible}
    <div class="text-red text-right text-xs pt-1 error-hint" role="alert">
      {#if errorIsVisible}
        {customValidityMessage || validityErrorMessage || ''}
      {/if}
    </div>
  {/if}
</div>

<style lang="postcss">
  input {
    transition: all var(--label-transition-speed) ease-in-out;
    @apply border-gray-100 placeholder-gray-200 high-contrast:border-gray-400 high-contrast:placeholder-gray-500 focus:outline-blue focus:border-blue;
    &.error {
      @apply border-red-50 placeholder-red-100 high-contrast:border-red-100 high-contrast:placeholder-red-400;
      & + label {
        @apply text-red;
      }
    }
  }

  /**
   * The label when the textfield is empty
   */
  label {
    transition: top var(--label-transition-speed) ease-in-out,
      left var(--label-transition-speed) ease-in-out,
      color var(--label-transition-speed) ease-in-out,
      font-size var(--label-transition-speed) ease-in-out,
      padding var(--label-transition-speed) ease-in-out;
    /* The px, py values should match the input's values */
    @apply absolute left-0 top-0 px-5 py-3 text-gray-200 high-contrast:text-gray-500;
  }

  /**
   * Make the label blue when focused. (See above outline-blue prop on input)
   */
  input:focus + label {
    @apply text-blue;
  }

  /**
   * When the input is not empty, or it's focused, or we have both an input AND
   * a placeholder, then we should display the label up and to the left.
   */
  input:focus + label,
  label.not-empty,
  input:placeholder-shown + label {
    @apply absolute -top-2 py-0 block bg-white left-3 px-2 text-sm;
  }

  :global(.high-contrast) {
    .error {
      @apply border-red-100 placeholder-red-400;
    }
  }

  .error-hint {
    min-height: 4ex;
  }
</style>
